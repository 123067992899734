import React, { useEffect } from "react"
import { IntlProvider, navigate, useIntl } from "gatsby-plugin-intl"

import LogosBlock, { LogoLines } from "../../components/pages/home/logos"
import Layout from "../../components/site/layout"
import Seo from "../../components/common/seo"
import Illustrations from "../../components/illustrations"
import SignUp from "../../components/blocks/signUp"
import { PageContent, Section } from "../../components/common/layout"
import {
  getLocale,
  getPathName,
  messagesForLanguage,
} from "../../utils/localized"
import SolutionSummary from "../../components/blocks/solutionSummary"
import PresenterHero from "../../components/heroes/presenter"
import IntegrationsBlock from "../../components/pages/solutions/integrations"
import Icons from "../../components/icons"
import FeaturesBlock, {
  ITrackedFeaturedItem,
} from "../../components/pages/solutions/features"
import Screenshots from "../../components/images/screenshots"
import ContactBlock from "../../components/pages/blocks/contact"
import BeliefsBlock from "../../components/pages/blocks/beliefs"
import Button from "../../components/buttons"
import Forms from "../../utils/typeform"
import Photos from "../../components/images/photos"
import Logos from "../../components/logos"
import NetworkBlock from "../../components/pages/solutions/network"

const OrganizationsContent = () => {
  const intl = useIntl()
  return (
    <PageContent>
      <Section h-m-lg t-flush h-p-lg>
        <PresenterHero
          title={intl.formatMessage({
            id: "solutions.organizations.hero.title",
          })}
          color="accent"
          superTitle={intl.formatMessage({
            id: "solutions.hero.supTitle",
          })}
          subtitle={intl.formatMessage({
            id: "solutions.organizations.hero.description",
          })}
          illustration={<Illustrations.Org.Constrained />}
          illustrationTransforms={{
            horizontal: "scale(1.2) translateY(32px)",
          }}
          button={
            <Button
              color="accent"
              small
              onClick={() => Forms.contact(intl)}
              text={intl.formatMessage({
                id: "solutions.organizations.callToAction",
              })}
            />
          }
        />
      </Section>

      <Section t-flush h-m-sm>
        <SolutionSummary
          color="accent"
          callToAction={{
            intlKey: "solutions.seePlans",
            color: "accent",
            small: true,
            onClick: () => navigate("/pricing/organizations"),
          }}
          messages={{
            title: "organizations.summary.title",
            body: "organizations.summary.body",
            secureTitle: "organizations.summary.secure.title",
            secureDescription: "organizations.summary.secure.description",
            independentTitle: "organizations.summary.independent.title",
            independentDescription:
              "organizations.summary.independent.description",
            flexibleTitle: "organizations.summary.flexible.title",
            flexibleDescription: "organizations.summary.flexible.description",
          }}
        />
      </Section>

      <Section t-xlg>
        <IntegrationsBlock />
      </Section>

      <Section h-m-xlg t-xxlg>
        <LogosBlock
          title={intl.formatMessage({ id: "logos.certifications.title" })}
          logoHeight={{ horizontal: 120, vertical: 120 }}
          logos={certificationsLogos}
          color="accent"
        />
      </Section>

      <Section t-xlg>
        <NetworkBlock />
      </Section>

      <Section t-xlg>
        <FeaturesBlock
          titleKey="solutions.organizations.showcase.title"
          color="accent"
          features={features}
        />
      </Section>

      <Section t-lg h-centered>
        <ContactBlock />
      </Section>

      <Section t-xlg h-centered h-m-sm>
        <SignUp />
      </Section>
    </PageContent>
  )
}

const OrganizationsPage = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("user-category", "organizations")
    }
  })

  return (
    <Layout>
      <OrganizationsContent />
    </Layout>
  )
}

export const Head = () => {
  const locale = getLocale()
  return (
    <IntlProvider messages={messagesForLanguage(locale)} locale={locale}>
      <Seo path={getPathName()} descriptionId="meta.description" />
    </IntlProvider>
  )
}

export default OrganizationsPage

const features: ITrackedFeaturedItem[] = [
  {
    key: "solutions.organizations.showcase.security",
    icon: Icons.LockCheck,
    Screenshot: Screenshots.Security,
  },
  {
    key: "solutions.organizations.showcase.archiving",
    icon: Icons.Cloud,
    Screenshot: Screenshots.PDFExport,
  },
  {
    key: "solutions.organizations.showcase.team",
    icon: Icons.Persons,
    Screenshot: Screenshots.Team,
  },
  {
    key: "solutions.organizations.showcase.multiuser",
    icon: Icons.Persons,
    Screenshot: Screenshots.MultiUser,
  },
  {
    key: "solutions.organizations.showcase.channels",
    icon: Icons.Document,
    Screenshot: Screenshots.CareChannels,
  },
]

export const certificationsLogos: LogoLines = [
  [
    { Logo: Logos.PIPEDA },
    { Logo: Logos.TGV },
    { Logo: Logos.PHIPA },
    { Logo: Logos.HIPAA },
  ],
]
