import React from "react"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import { getFromTheme as theme } from "../../../utils/styles"
import { H3, P } from "../../common/typography"
import Marker from "../../markers"
import HighlightedText from "../../common/highlightedText"
import Icons from "../../icons"
import Illustrations from "../../illustrations"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Button from "../../buttons"
import Forms from "../../../utils/typeform"
import Photos from "../../images/photos"

const ValuePropsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 64px;

  @media (max-width: ${theme("breakpoints.large")}px) {
    flex-direction: column;
    gap: 32px;
  }

  > * {
    flex-basis: 100%;
  }
`

const IllustrationWrapper = styled.div`
  @media (max-width: ${theme("breakpoints.large")}px) {
    align-self: center;
    transform: scale(1.1);
  }
`

const ValuePropStatement = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${P} {
    text-align: left;

    @media (min-width: ${theme("breakpoints.large")}px) {
      max-width: 20vw;
    }
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme("breakpoints.large")}px) {
    max-width: 33vw;
  }
  @media (max-width: ${theme("breakpoints.medium")}px) {
    margin: 0 16px;
  }

  ${H3} {
    margin-bottom: 32px;
  }

  ${Description} {
    margin-bottom: 32px;
  }

  ${ValuePropStatement} {
    margin-bottom: 16px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 72px;

  @media (max-width: ${theme("breakpoints.medium")}px) {
    gap: 32px;
  }

  img {
    border-radius: 12px;
  }
`

const NetworkBlock = ({}) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <ValuePropsWrapper>
        <Photos.ClinicianWoman2 />
        <Content>
          <H3 fluid>
            <HighlightedText
              color="primary"
              textId="organizations.network.title"
            />
          </H3>

          <Description>
            <P>
              <FormattedMessage id="organizations.network.description" />
            </P>

            <Button.Underline
              color="primary"
              buttonStyle="icon"
              Icon={Icons.Letter.Medium}
              text={intl.formatMessage({
                id: "blocks.contact.callToAction",
              })}
              onClick={() => Forms.contact(intl)}
            />
          </Description>

          <Fade up delay={200}>
            <ValuePropStatement>
              <Marker color="primary" Icon={Icons.Partage} />
              <P>
                <HighlightedText
                  color="primary"
                  textId="organizations.network.collaboration"
                />
              </P>
            </ValuePropStatement>
          </Fade>
          <Fade up delay={300}>
            <ValuePropStatement>
              <Marker color="primary" Icon={Icons.PersonAdd} />
              <P>
                <HighlightedText
                  color="primary"
                  textId="organizations.network.invitation"
                />
              </P>
            </ValuePropStatement>
          </Fade>
          <Fade up delay={400}>
            <ValuePropStatement>
              <Marker color="primary" Icon={Icons.FileCheck} />
              <P>
                <HighlightedText
                  color="primary"
                  textId="organizations.network.trajectories"
                />
              </P>
            </ValuePropStatement>
          </Fade>
        </Content>
      </ValuePropsWrapper>
    </Wrapper>
  )
}

export default NetworkBlock
